// types
import type { Enrollment, Nullable } from '@revolutionprep/types'

// utilities
import { round } from '@revolutionprep/utils'

export function useUnscheduledHours () {
  /**
   * hours copy
   * ==================================================================
   */
  function getHoursCopy (
    hours: number,
    includeHourValue = true
  ) {
    return hours !== 1
      ? includeHourValue ? `${hours} hours` : 'hours'
      : includeHourValue ? `${hours} hour` : 'hour'
  }

  /**
   * single enrollment
   * ==================================================================
   */
  function getScheduledHours (
    enrollment: Nullable<Enrollment> | undefined
  ) {
    return enrollment?.course?.duration
      ? round(enrollment.course.duration / 60)
      : 0
  }

  function getUnscheduledDuration (
    enrollment: Nullable<Enrollment> | undefined
  ) {
    return enrollment?.course?.unscheduledDuration || 0
  }

  function getUnscheduledHours (
    enrollment: Nullable<Enrollment> | undefined
  ) {
    return round(getUnscheduledDuration(enrollment) / 60)
  }

  /**
   * multiple enrollments
   * ==================================================================
   */
  function getTotalUnscheduledDuration (enrollments: Enrollment[]) {
    return enrollments.reduce((
      unscheduledDuration,
      enrollment
    ) => {
      const courseUnscheduledDuration =
        Math.max(enrollment.course?.unscheduledDuration || 0, 0)
      unscheduledDuration += courseUnscheduledDuration
      return unscheduledDuration
    }, 0)
  }

  function getTotalUnscheduledHours (enrollments: Enrollment[]) {
    return round(getTotalUnscheduledDuration(enrollments) / 60)
  }

  return {
    getHoursCopy,
    getScheduledHours,
    getTotalUnscheduledDuration,
    getTotalUnscheduledHours,
    getUnscheduledDuration,
    getUnscheduledHours
  }
}
